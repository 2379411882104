import React, { Component } from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { navigate } from "gatsby"

import "../scss/usecase.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { setDefaultHeaderTheme, navbarUpdate } from "../utils/common-util"
import { getUsecases } from "../data/usecases"
import UsecaseImg from "../components/usecase-img"

class UsecasePage extends Component {
  constructor(props) {
    super(props)

    this.topContRef = React.createRef()
    this.navbarRef = React.createRef()
    this.rightContRef = React.createRef()
    this.strategySolutionsRef = React.createRef()
    this.transformationRef = React.createRef()
    this.theChallengeRef = React.createRef()

    this.usecases = getUsecases()
    this.navLeftPad = 0

    this.state = {
      currUcIdx: 0,
      currUsecase: this.usecases[0],
    }

    this.onPageScroll = this.onPageScroll.bind(this)
  }

  onPageScroll = () => {
    let $mainCont = document.getElementById("main-container")
    let scrollHeight =
      this.topContRef.current.offsetHeight +
      ($mainCont.classList.contains("header-visible") ? 60 : 20)
    if (window.scrollY >= scrollHeight) {
      !this.navbarRef.current.classList.contains("uc-fixed") &&
        this.navbarRef.current.classList.add("uc-fixed")
    } else {
      this.navbarRef.current.classList.remove("uc-fixed")
    }

    let diff = $mainCont.classList.contains("header-visible") ? 90 : 60
    if (
      window.scrollY >= this.theChallengeRef.current.offsetTop - diff &&
      window.scrollY < this.strategySolutionsRef.current.offsetTop - diff
    ) {
      this.toggleSecNavbar(
        document.querySelector(
          ".uc-navbar > span." + this.theChallengeRef.current.id
        )
      )
    } else if (
      window.scrollY >= this.strategySolutionsRef.current.offsetTop - diff &&
      window.scrollY < this.transformationRef.current.offsetTop - diff
    ) {
      this.toggleSecNavbar(
        document.querySelector(
          ".uc-navbar > span." + this.strategySolutionsRef.current.id
        )
      )
    } else if (
      window.scrollY >=
      this.transformationRef.current.offsetTop - diff
    ) {
      this.toggleSecNavbar(
        document.querySelector(
          ".uc-navbar > span." + this.transformationRef.current.id
        )
      )
    }
  }

  onUsecaseChange = act => {
    let newIndex = this.state.currUcIdx + act
    navigate("/usecase?case=" + newIndex)
    this.setState({
      currUcIdx: newIndex,
      currUsecase: this.usecases[newIndex],
    })
    this.adjustRightContWH()
  }

  toggleSecNavbar = $target => {
    let $selected = document.querySelector(".uc-navbar > span.top-in-view")
    $selected && $selected.classList.remove("top-in-view")
    $target.classList.add("top-in-view")

    let $nav = this.navbarRef.current.firstElementChild
    if ($nav.offsetWidth > this.navbarRef.current.offsetWidth) {
      this.navbarRef.current.scrollTo({
        left: $target.offsetLeft - this.navLeftPad,
      })
    }
  }

  adjustRightContWH = () => {
    setTimeout(() => {
      let $rigthImg = this.rightContRef.current.firstElementChild
      let height = this.rightContRef.current.offsetHeight
      let width = height * 1.51
      $rigthImg.style.height = height + "px"
      $rigthImg.style.width = width.toFixed(1) + "px"
    }, 200)
  }

  componentDidMount() {
    let query = new URLSearchParams(this.props.location.search)
    setDefaultHeaderTheme(true)
    navbarUpdate("nav-about")
    this.navLeftPad = this.navbarRef.current.querySelector(
      "span.the-challenge"
    ).offsetLeft
    window.addEventListener("scroll", this.onPageScroll, false)
    let caseId = this.props.location.search ? parseInt(query.get("case")) : null
    if (query && caseId) {
      this.setState({
        currUcIdx: caseId,
        currUsecase: this.usecases[caseId],
      })
    }
    this.adjustRightContWH()
    if (window.scrollY > 0) {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        })
      }, 200)
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onPageScroll, false)
  }

  render() {
    const points = (pnts, key) =>
      pnts ? (
        <div className="uc-row-points">
          {pnts.map((pt, i) => {
            if (typeof pt === "string") {
              return (
                <div key={key + i} className="uc-row-point">
                  {pt}
                </div>
              )
            } else {
              return (
                <div key={key + i}>
                  <div className="uc-row-point has-sub-pts">{pt.text}</div>
                  {points(pt.subPoints, key + "-SPT")}
                </div>
              )
            }
          })}
        </div>
      ) : null

    const images = (imgs, key) => {
      if (imgs) {
        let imgsClass = ""
        if (imgs.length > 6) {
          imgsClass = " imgs-four"
        } else if (imgs.length > 4) {
          imgsClass = " imgs-three"
        } else if (imgs.length >= 2) {
          imgsClass = " imgs-two"
        }
        return (
          <div className={"uc-row-images" + imgsClass}>
            {imgs.map((img, i) => {
              let name = this.state.currUsecase.key + "_" + key + "_" + img
              return (
                <div key={key + i} className="uc-row-img">
                  <UsecaseImg name={name}></UsecaseImg>
                </div>
              )
            })}
          </div>
        )
      }
    }

    const getPrevUsecase = () => {
      this.onUsecaseChange(-1)
    }

    const getNextUsecase = () => {
      this.onUsecaseChange(1)
    }

    const goToSection = key => {
      let $mainCont = document.getElementById("main-container")
      let $sec = document.getElementById(key)
      window.scrollTo({
        top:
          $sec.offsetTop -
          ($mainCont.classList.contains("header-visible") ? 90 : 60),
        behavior: "smooth",
      })
    }

    return (
      <Layout>
        <SEO title={"Usecase - " + this.state.currUsecase.title} />
        <div ref={this.topContRef} className="uc-top-contents">
          <div className="left-cont">
            {this.state.currUcIdx > 0 ? (
              <>
                <button
                  className="button is-large usecase-np-btn ucnb-prev"
                  onClick={getPrevUsecase}
                >
                  <span className="icon is-medium">
                    <i className="fas fa-angle-left fa-3x"></i>
                  </span>
                </button>
              </>
            ) : null}
            <AniLink
              className="back-to-usecases faa-parent animated-hover"
              to="/usecases/"
            >
              <i className="fas fa-chevron-left faa-passing reverse"></i>
              <span>Go back</span>
            </AniLink>
            <h1 className="uc-heading">{this.state.currUsecase.head}</h1>
            <div className="uc-desc">{this.state.currUsecase.desc}</div>
            {this.state.currUcIdx < this.usecases.length - 1 ? (
              <>
                <button
                  className="button is-large usecase-np-btn ucnb-next"
                  onClick={getNextUsecase}
                >
                  <span className="icon is-medium">
                    <i className="fas fa-angle-right fa-3x"></i>
                  </span>
                </button>
              </>
            ) : null}
          </div>
          <div ref={this.rightContRef} className="right-cont">
            <UsecaseImg name={this.state.currUsecase.key}></UsecaseImg>
          </div>
        </div>
        <div ref={this.navbarRef} className="uc-navbar-wrap">
          <div className="uc-navbar">
            <span
              className="the-challenge"
              onClick={goToSection.bind(this, "the-challenge")}
            >
              Challenge
            </span>
            <span
              className="strategy-solutions"
              onClick={goToSection.bind(this, "strategy-solutions")}
            >
              Strategy and Solutions
            </span>
            <span
              className="transformation"
              onClick={goToSection.bind(this, "transformation")}
            >
              Transformation
            </span>
          </div>
        </div>
        <div
          id="the-challenge"
          ref={this.theChallengeRef}
          key="the-challenge"
          className="uc-row"
        >
          <div className="uc-row-head">The Challenge</div>
          <div className="uc-row-desc">{this.state.currUsecase.chl}</div>
        </div>
        <div
          id="strategy-solutions"
          ref={this.strategySolutionsRef}
          key="strategy-solutions"
          className="uc-row"
        >
          <div className="uc-row-head">Strategy and Solutions</div>
          <div className="uc-row-desc">{this.state.currUsecase.ssn.desc1}</div>
          {points(this.state.currUsecase.ssn.points1, "ss_pnts1")}
          {images(this.state.currUsecase.ssn.imgs1, "ss_imgs1")}
          {this.state.currUsecase.ssn.desc2 ? (
            <div className="uc-row-desc">
              {this.state.currUsecase.ssn.desc2}
            </div>
          ) : null}
          {points(this.state.currUsecase.ssn.points2, "ss_pnts2")}
          {images(this.state.currUsecase.ssn.imgs2, "ss_imgs2")}
        </div>
        <div
          id="transformation"
          ref={this.transformationRef}
          key="transformation"
          className="uc-row"
        >
          <div className="uc-row-head">Transformation</div>
          <div className="uc-row-desc">{this.state.currUsecase.trf.desc1}</div>
          {points(this.state.currUsecase.trf.points1, "tr_pnts1")}
          {images(this.state.currUsecase.ssn.imgs1, "tr_imgs1")}
        </div>
        <div className="uc-bottom-cont">
          <OutboundLink
            href={this.state.currUsecase.doc}
            rel="noopener noreferrer"
            target="_blank"
            className="button is-primary is-medium"
          >
            Get as PDF
          </OutboundLink>
          <div className="uc-prev-next">
            <div onClick={getPrevUsecase} className="uc-prev">
              {this.state.currUcIdx > 0 ? (
                <>
                  <div className="arrow"></div>
                  <div className="pn-info">
                    <div className="pn-label">Previous</div>
                    <div className="pn-head">
                      {this.usecases[this.state.currUcIdx - 1].head}
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            <div onClick={getNextUsecase} className="uc-next">
              {this.state.currUcIdx < this.usecases.length - 1 ? (
                <>
                  <div className="pn-info">
                    <div className="pn-label">Next</div>
                    <div className="pn-head">
                      {this.usecases[this.state.currUcIdx + 1].head}
                    </div>
                  </div>
                  <div className="arrow"></div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default UsecasePage
